<template>
  <div class="fail">
    <div
        class="code-container"
        v-show="showCodeContainer"
    >
      <img
          :src="codeUrl"
          alt=""
          class="code-icon"
      />
      <img
          :src="copyTextImageUrl"
          alt=""
          class="bottom-banner"
          @click="copyText(codeText)"
      />
    </div>
    <div
        class="failed-container"
        v-show="!showCodeContainer"
    >
      <img
          src="https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/fail/fail.png"
          alt=""
          class="failed-icon"
      >
      <div>很抱歉，申请失败</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Fail',
  data() {
    return {
      showCodeContainer: process.env.VUE_APP_HAS_CODE_POPUP || process.env.VUE_APP_HAS_NEW_CODE_POPUP,
      codeUrl: process.env.VUE_APP_NEW_CODE_URL || 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/fail/code.jpg',
      codeText: process.env.VUE_APP_NEW_CODE_TEXT || '51卡包',
      copyTextImageUrl: process.env.VUE_APP_NEW_CODE_TEXT_IMAGE_URL || 'https://duoshangg.fengzhui.cn/web/51-loan/whole-process/images/super-loan/copy-text-image.png'
    }
  },
  methods: {
    // 复制文案至剪切板
    copyText(text) {
      const inputElement = document.createElement("input")
      inputElement.value = text
      document.body.appendChild(inputElement)
      inputElement.select()
      document.execCommand("copy")
      document.body.removeChild(inputElement)
      this.$toast.success('复制成功')
    }
  },
  created() {
    localStorage.setItem('isFailed', '1')
  }
}
</script>

<style scoped>
.code-icon {
  width: 9.333rem;
  margin: 2.88rem auto 0.96rem;
}

.bottom-banner {
  position: fixed;
  left: 50%;
  bottom: 16px;
  width: 9.147rem;
  transform: translateX(-50%);
}

.failed-container {
  text-align: center;
  font-size: 0.48rem;
  font-weight: 500;
  color: #333333;
}

.failed-icon {
  width: 2.133rem;
  margin: 2.88rem auto 0.533rem;
}
</style>